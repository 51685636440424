html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

#app,
#app > [data-reactroot],
[data-reactroot] > div {
  height: 100%;
  width: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  margin: 0;
  user-select: none;
}

a {
  text-decoration: none;
}

a,
p,
div,
label,
button {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  user-select: none;
}

img {
  border: none;
}

form,
label,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

input {
  border: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

textarea {
  border: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

a,
div,
button {
  outline: none;
}

a:hover,
a:active,
a:focus,
div:hover,
div:active,
div:focus,
button:hover,
button:active,
button:focus {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

a,
input,
textarea,
label,
button,
div {
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
}

input:focus {
  outline: none;
}

a,
button {
  border: none;
  border-radius: 64px;
  cursor: pointer;
  font-weight: 600;
  padding: 24px;
}

a:hover,
button:hover {
  color: #000;
  background-color: #f8f8f8;
}

@media only screen and (max-width: 750px) {
  .mobile-column {
    flex-direction: column !important;
    width: 100% !important;
    align-items: center !important;
  }
}
