.react-code-input {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.react-code-input input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* font-size: 24px !important; */
  font-weight: 600;
  border: none !important;
  border-radius: 80px !important;
  width: 60px !important;
  padding: 16px !important;
  height: 60px !important;
  text-align: center !important;
  margin: 0 8px 8px !important;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2) !important;
}

.react-code-input input:focus {
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.2) !important;
}
