.status-enter {
  margin: 16px;
  opacity: 0;
  overflow: hidden;
}

.status-enter.status-enter-active {
  margin: 16px;
  opacity: 1;
}

.status-leave {
  opacity: 1;
  margin: 16px;
}

.status-leave.status-leave-active {
  opacity: 0;
  margin: 16px;
}

.error-enter {
  margin: 0;
  max-height: 0;
  overflow: hidden;
}

.error-enter.error-enter-active {
  height: auto;
  margin: 10px 0 0;
  max-height: 100px;
}

.error-leave {
  margin: 10px 0 0;
  max-height: 100px;
}

.error-leave.error-leave-active {
  margin: 0;
  max-height: 0;
  overflow: hidden;
}

.modal-enter {
  opacity: 0.1;
}

.modal-enter.modal-enter-active {
  opacity: 1;
}

.modal-leave {
  opacity: 1;
}

.modal-leave.modal-leave-active {
  opacity: 0.1;
}

.sidemenu-enter {
  transform: translateX(-100%);
}

.sidemenu-enter.sidemenu-enter-active {
  transform: translateX(0);
}

.sidemenu-leave {
  transform: translateX(0);
}

.sidemenu-leave.sidemenu-leave-active {
  transform: translateX(-100%);
}

.actionsheet-enter {
  transform: translate3d(0, 101%, 0);
}

.actionsheet-enter.actionsheet-enter-active {
  transform: translate3d(0, 0, 0);
}

.actionsheet-leave {
  transform: translate3d(0, 0, 0);
}

.actionsheet-leave.actionsheet-leave-active {
  transform: translate3d(0, 101%, 0);
}

.dropDown-appear {
  transform: translateY(-100%);
  opacity: 0.1;
}

.dropDown-appear.dropDown-appear-active {
  transform: translateY(0);
  opacity: 1;
}

.dropDown-leave {
  transform: translateY(0);
  opacity: 1;
}

.dropDown-leave.dropDown-leave-active {
  transform: translateY(-100%);
  opacity: 0.1;
}
